import React, { useState, useEffect } from "react"
import usePrefersReducedMotion from "../hooks/useReducedMotion"
import useThrottledResizeObserver from "../hooks/useThrottledResizeObserver"

const indexOfLowest = arr => {
  // arr = array of numbers smaller than positive infinity
  // returns index of smallest value in arr
  return arr.reduce(
    (acc, cur, ind, arr) =>
      cur < (acc === Infinity ? acc : arr[acc]) ? ind : acc,
    Infinity
  )
}

const sizeToNumInSpotlight = (spotlightBreakpoints, size) => {
  // can be cleaned
  const brpts = spotlightBreakpoints
  brpts[100000] = 100
  const keyArray = Object.keys(spotlightBreakpoints).map(key => parseInt(key))
  const numBreakpoints = keyArray.length
  let numInSpotlight = null
  if (numBreakpoints === 1) {
    numInSpotlight = spotlightBreakpoints[keyArray[0].toString()]
  } else {
    for (let i = 0; i < numBreakpoints - 1; i++) {
      if (size >= keyArray[i] && size < keyArray[i + 1]) {
        numInSpotlight = spotlightBreakpoints[keyArray[i].toString()]
      }
    }
  }
  return numInSpotlight
}

const findIndsInSpotlight = (numInSpotlight, current) => {
  // can be cleaned
  const activeVals = [...Array(numInSpotlight).keys()]
  const indices = activeVals.map(val => {
    if (current.indexOf(val) !== -1) {
      return current.indexOf(val)
    } else {
      return []
    }
  })
  return indices
}

const Carousel = ({ children, spotlightBreakpoints, cycleTime, classes }) => {
  /*
    children - JSX children, should be at least 3 divs
    spotlightBreakpoints - Object {"pixels": "number of divs in spotlight when size of carousel is above pixels"} 
    cycleTime - int - time in milliseconds before a slide should go past
    classes - string of tailwind classes to be added to embodying div
  */
  const { ref, width } = useThrottledResizeObserver(500)
  const numChildren = React.Children.count(children)
  const parentArray = [...Array(numChildren).keys()]
  const [current, setCurrent] = useState([...Array(numChildren).keys()])

  const prefersReducedMotion = usePrefersReducedMotion()

  const animationDur = prefersReducedMotion ? 1 : 650 // ms
  const buffer = 350 // ms

  const numInSpotlight = sizeToNumInSpotlight(spotlightBreakpoints, width)
  const indsInSpotlight = findIndsInSpotlight(numInSpotlight, current)

  useEffect(() => {
    const loop = setInterval(() => {
      setCurrent(current => current.map(val => val - 1))
    }, cycleTime * 1000)

    return () => clearInterval(loop)
  }, [])

  useEffect(() => {
    const lowestInd = indexOfLowest(current)
    if (current[lowestInd] < 0) {
      setTimeout(() => {
        setCurrent(current => {
          let newCurrent = [...current]
          newCurrent[lowestInd] = newCurrent[lowestInd] + numChildren
          return newCurrent
        })
      }, animationDur + buffer)
    }
  }, [current])

  return (
    <div className="w-full" ref={ref}>
      <div className="w-full flex flex-row overflow-hidden">
        {React.Children.map(children || null, (child, i) => {
          return (
            <div
              key={i}
              className={`flex-shrink-0 w-full ${classes ? classes : ``}`}
              style={{
                transform: `translateX(${(parentArray[i] - current[i]) *
                  -1 *
                  (width / numInSpotlight)}px)`,
                willChange: "transform",
                willChange: "opacity",
                transition: `all ${animationDur}ms cubic-bezier(0.215,0.61,0.355,1), width 1ms linear`,
                width: `${width / numInSpotlight}px`,
                opacity: current[i] >= 0 && current[i] < numInSpotlight ? 1 : 0,
              }}
            >
              <child.type {...child.props} key={i} />
            </div>
          )
        })}
      </div>
      <div className="pt-4 flex justify-center">
        {[...Array(numChildren).keys()].map((item, index) => (
          <div
            key={`card-${index}`}
            className={`rounded-full h-2 w-2 m-1 
                ${
                  indsInSpotlight.indexOf(index) !== -1
                    ? `bg-blue-700 opacity-75`
                    : `bg-gray-500 opacity-50`
                }`}
            style={{ transition: `all ${animationDur / 2}ms ease-in` }}
          ></div>
        ))}
      </div>
    </div>
  )
}

export default Carousel
